import React, { useEffect, useRef } from 'react';
import { useChatStore } from '../../lib/sarah/store';
import { DialogueOption } from '../../lib/sarah/types';
import { getNextDialogue } from '../../lib/sarah/dialogue';

export const ChatMessages: React.FC = () => {
  const { messages, addMessage } = useChatStore();
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const [isTyping, setIsTyping] = React.useState(false);

  const handleOptionClick = async (option: DialogueOption) => {
    addMessage({ type: 'user', content: option.label });
    
    const nextDialogue = getNextDialogue(option.nextId);
    if (nextDialogue) {
      setIsTyping(true);
      await new Promise(resolve => setTimeout(resolve, 1000));
      setIsTyping(false);
      
      addMessage({ 
        type: 'bot', 
        content: nextDialogue.message,
        options: nextDialogue.options 
      });
    }
  };

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages, isTyping]);

  return (
    <div className="flex-1 overflow-y-auto p-4 space-y-4">
      {messages.map((message) => (
        <div key={message.id} className="space-y-2 animate-fadeIn">
          <div className={`flex ${message.type === 'user' ? 'justify-end' : 'justify-start'}`}>
            <div
              className={`max-w-[80%] p-3 rounded-lg ${
                message.type === 'user'
                  ? 'bg-[#004BA8] text-white'
                  : 'bg-gray-100 text-gray-800'
              }`}
            >
              {message.content}
            </div>
          </div>
          
          {message.type === 'bot' && message.options && (
            <div className="flex flex-wrap gap-2 mt-2">
              {message.options.map((option) => (
                <button
                  key={option.value}
                  onClick={() => handleOptionClick(option)}
                  className="bg-white border-2 border-[#004BA8] text-[#004BA8] px-4 py-2 rounded-full text-sm hover:bg-[#004BA8] hover:text-white transition-all duration-300 transform hover:scale-105"
                >
                  {option.label}
                </button>
              ))}
            </div>
          )}
        </div>
      ))}
      {isTyping && (
        <div className="flex items-center space-x-2 text-gray-500">
          <div className="w-2 h-2 bg-gray-400 rounded-full animate-bounce" />
          <div className="w-2 h-2 bg-gray-400 rounded-full animate-bounce" style={{ animationDelay: '0.2s' }} />
          <div className="w-2 h-2 bg-gray-400 rounded-full animate-bounce" style={{ animationDelay: '0.4s' }} />
        </div>
      )}
      <div ref={messagesEndRef} />
    </div>
  );
};