import React from 'react';
import { Link } from 'react-router-dom';
import { Users, Building, TrendingUp, Wallet, BarChart3, Shield } from 'lucide-react';

const personas = [
  {
    title: 'Investor',
    path: '/investor-signup',
    icon: Users,
    color: '#2F977E',
    features: [
      { icon: Wallet, text: 'Minimum investment of $1,000' },
      { icon: TrendingUp, text: 'Returns between 15-20%' },
      { icon: Shield, text: 'Secure, verified opportunities' }
    ],
    description: 'Access curated investment opportunities and earn competitive returns through secure debt financing.'
  },
  {
    title: 'Company',
    path: '/company-signup',
    icon: Building,
    color: '#004BA8',
    features: [
      { icon: BarChart3, text: 'Raise up to $1M+' },
      { icon: Shield, text: 'Secure debt financing' },
      { icon: Users, text: 'Access verified investors' }
    ],
    description: 'List your business and access growth capital through our network of verified investors.'
  }
];

export const PersonaSelector: React.FC = () => {
  return (
    <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 px-4 sm:px-6 lg:px-8">
      <div className="text-center mb-12">
        <h1 className="text-4xl font-bold text-gray-900 mb-4">Choose Your Path</h1>
        <p className="text-xl text-gray-600 max-w-2xl mx-auto">
          Select how you'd like to participate in the Gainz platform
        </p>
      </div>

      <div className="max-w-7xl mx-auto grid grid-cols-1 md:grid-cols-2 gap-8 px-4">
        {personas.map((persona) => (
          <Link
            key={persona.title}
            to={persona.path}
            className="group relative bg-white rounded-xl shadow-lg hover:shadow-xl transition-all duration-300 p-8"
          >
            <div className="absolute inset-0 rounded-xl transition-colors duration-300 group-hover:bg-gray-50" />
            <div className="relative flex flex-col items-center">
              <div
                className="w-20 h-20 rounded-full flex items-center justify-center mb-6 transition-transform duration-300 group-hover:scale-110"
                style={{ backgroundColor: `${persona.color}20` }}
              >
                <persona.icon size={40} className="transition-colors duration-300" style={{ color: persona.color }} />
              </div>
              
              <h2 className="text-2xl font-bold mb-4" style={{ color: persona.color }}>
                {persona.title}
              </h2>
              
              <p className="text-gray-600 text-center mb-8">
                {persona.description}
              </p>

              <div className="w-full space-y-4">
                {persona.features.map((feature, index) => (
                  <div key={index} className="flex items-center space-x-3">
                    <feature.icon size={20} className="text-gray-400" />
                    <span className="text-gray-600">{feature.text}</span>
                  </div>
                ))}
              </div>

              <div className="mt-8 w-full">
                <button
                  className="w-full py-3 px-6 rounded-lg text-white transition-all duration-300 transform hover:scale-105"
                  style={{ backgroundColor: persona.color }}
                >
                  Get Started
                </button>
              </div>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};