export const companies = [
  {
    id: '1',
    name: 'TechFlow Solutions',
    description: {
      en: 'B2B SaaS platform revolutionizing supply chain management',
      ar: 'منصة برمجية تجارية ثورية لإدارة سلسلة التوريد'
    },
    industry: 'Technology',
    fundingGoal: 500000,
    currentFunding: 375000,
    returnRate: 18,
    tenure: 9,
    logo: 'https://images.unsplash.com/photo-1486406146926-c627a92ad1ab?w=500',
    location: 'Dubai'
  },
  {
    id: '2',
    name: 'EcoGreen Energy',
    description: {
      en: 'Renewable energy solutions for commercial buildings',
      ar: 'حلول الطاقة المتجددة للمباني التجارية'
    },
    industry: 'Clean Energy',
    fundingGoal: 750000,
    currentFunding: 525000,
    returnRate: 15,
    tenure: 12,
    logo: 'https://images.unsplash.com/photo-1497435334941-8c899ee9e8e9?w=500',
    location: 'Abu Dhabi'
  },
  {
    id: '3',
    name: 'HealthTech Plus',
    description: {
      en: 'AI-powered healthcare diagnostics platform',
      ar: 'منصة تشخيص طبي مدعومة بالذكاء الاصطناعي'
    },
    industry: 'Healthcare',
    fundingGoal: 400000,
    currentFunding: 280000,
    returnRate: 20,
    tenure: 6,
    logo: 'https://images.unsplash.com/photo-1576091160550-2173dba999ef?w=500',
    location: 'Riyadh'
  },
  {
    id: '4',
    name: 'Urban Logistics',
    description: {
      en: 'Last-mile delivery optimization platform',
      ar: 'منصة تحسين خدمات التوصيل النهائي'
    },
    industry: 'Logistics',
    fundingGoal: 600000,
    currentFunding: 450000,
    returnRate: 16,
    tenure: 8,
    logo: 'https://images.unsplash.com/photo-1494412574643-ff11b0a5c1c3?w=500',
    location: 'Cairo'
  },
  {
    id: '5',
    name: 'FoodTech Arabia',
    description: {
      en: 'Cloud kitchen management software',
      ar: 'برنامج إدارة المطابخ السحابية'
    },
    industry: 'Food Tech',
    fundingGoal: 300000,
    currentFunding: 195000,
    returnRate: 17,
    tenure: 10,
    logo: 'https://images.unsplash.com/photo-1556740758-90de374c12ad?w=500',
    location: 'Doha'
  },
  {
    id: '6',
    name: 'FinEdge Systems',
    description: {
      en: 'Digital payment infrastructure for SMEs',
      ar: 'بنية تحتية للمدفوعات الرقمية للشركات الصغيرة والمتوسطة'
    },
    industry: 'Fintech',
    fundingGoal: 800000,
    currentFunding: 640000,
    returnRate: 19,
    tenure: 7,
    logo: 'https://images.unsplash.com/photo-1559526324-593bc073d938?w=500',
    location: 'Kuwait City'
  }
];