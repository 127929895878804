import { Company } from '../types';

const industries = [
  'Technology', 'Healthcare', 'Fintech', 'E-commerce', 'Clean Energy',
  'Education', 'Logistics', 'Food Tech', 'Real Estate', 'Manufacturing'
];

const locations = [
  'Dubai', 'Abu Dhabi', 'Riyadh', 'Jeddah', 'Cairo',
  'Doha', 'Kuwait City', 'Muscat', 'Manama', 'Amman'
];

const companyPrefixes = [
  'Tech', 'Smart', 'Future', 'Next', 'Digital',
  'Global', 'Innovative', 'Modern', 'Advanced', 'Elite'
];

const companySuffixes = [
  'Solutions', 'Systems', 'Technologies', 'Innovations', 'Ventures',
  'Group', 'Industries', 'Enterprises', 'Labs', 'Co'
];

// Curated list of real Unsplash image URLs
const companyImages = [
  'https://images.unsplash.com/photo-1486406146926-c627a92ad1ab?w=500',
  'https://images.unsplash.com/photo-1497435334941-8c899ee9e8e9?w=500',
  'https://images.unsplash.com/photo-1576091160550-2173dba999ef?w=500',
  'https://images.unsplash.com/photo-1494412574643-ff11b0a5c1c3?w=500',
  'https://images.unsplash.com/photo-1556740758-90de374c12ad?w=500',
  'https://images.unsplash.com/photo-1559526324-593bc073d938?w=500',
  'https://images.unsplash.com/photo-1454165804606-c3d57bc86b40?w=500',
  'https://images.unsplash.com/photo-1460925895917-afdab827c52f?w=500',
  'https://images.unsplash.com/photo-1507679799987-c73779587ccf?w=500',
  'https://images.unsplash.com/photo-1556761175-5973dc0f32e7?w=500',
  'https://images.unsplash.com/photo-1556761175-b413da4baf72?w=500',
  'https://images.unsplash.com/photo-1542744173-8e7e53415bb0?w=500',
  'https://images.unsplash.com/photo-1486406146926-c627a92ad1ab?w=500',
  'https://images.unsplash.com/photo-1504384308090-c894fdcc538d?w=500',
  'https://images.unsplash.com/photo-1557804506-669a67965ba0?w=500',
  'https://images.unsplash.com/photo-1521791136064-7986c2920216?w=500',
  'https://images.unsplash.com/photo-1559526324-4b87b5e36e44?w=500',
  'https://images.unsplash.com/photo-1553877522-43269d4ea984?w=500',
  'https://images.unsplash.com/photo-1450101499163-c8848c66ca85?w=500',
  'https://images.unsplash.com/photo-1560179707-f14e90ef3623?w=500',
  'https://images.unsplash.com/photo-1554774853-719586f82d77?w=500',
  'https://images.unsplash.com/photo-1556761175-129418cb2dfe?w=500',
  'https://images.unsplash.com/photo-1542744094-24638eff58bb?w=500',
  'https://images.unsplash.com/photo-1542744173-05336fcc7ad4?w=500'
];

const generateRandomProgress = () => {
  return Math.random() * 0.9 + 0.1; // Random progress between 10% and 100%
};

export const generateMoreCompanies = (count: number): Company[] => {
  return Array.from({ length: count }, (_, i) => {
    const prefix = companyPrefixes[Math.floor(Math.random() * companyPrefixes.length)];
    const suffix = companySuffixes[Math.floor(Math.random() * companySuffixes.length)];
    const industry = industries[Math.floor(Math.random() * industries.length)];
    const location = locations[Math.floor(Math.random() * locations.length)];
    
    const fundingGoal = Math.floor(Math.random() * 900000) + 100000;
    const progress = generateRandomProgress();
    
    return {
      id: (i + 7).toString(),
      name: `${prefix} ${suffix}`,
      description: `Leading ${industry.toLowerCase()} company revolutionizing the industry with innovative solutions`,
      industry,
      fundingGoal,
      currentFunding: Math.floor(fundingGoal * progress),
      returnRate: Math.floor(Math.random() * 6) + 15,
      tenure: Math.floor(Math.random() * 7) + 6,
      logo: companyImages[i % companyImages.length],
      location
    };
  });
};