import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Navbar } from './components/Navbar';
import { Footer } from './components/Footer';
import { Hero } from './components/Hero';
import { CompanyCard } from './components/CompanyCard';
import { CompanyDetails } from './pages/CompanyDetails';
import { AboutPage } from './pages/AboutPage';
import { HowItWorksPage } from './pages/HowItWorksPage';
import { OpportunitiesPage } from './pages/OpportunitiesPage';
import { InvestorSignup } from './pages/InvestorSignup';
import { CompanySignup } from './pages/CompanySignup';
import { LoginPage } from './pages/LoginPage';
import { PersonaSelector } from './pages/PersonaSelector';
import { ContactPage } from './pages/ContactPage';
import { LinkedInCallback } from './pages/LinkedInCallback';
import { ChatWindow } from './components/sarah/ChatWindow';
import { companies } from './data/companies';

function App() {
  return (
    <Router>
      <div className="min-h-screen bg-gray-50 flex flex-col">
        <Navbar />
        <div className="flex-grow">
          <Routes>
            <Route path="/" element={
              <>
                <Hero />
                <main className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
                  <div className="text-center mb-12">
                    <h2 className="text-3xl font-bold text-gray-900 mb-4">
                      Featured Investment Opportunities
                    </h2>
                    <p className="text-gray-600 max-w-2xl mx-auto">
                      Discover curated investment opportunities with verified companies
                      offering competitive returns through secure debt financing
                    </p>
                  </div>
                  
                  <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                    {companies.map((company) => (
                      <CompanyCard key={company.id} company={company} />
                    ))}
                  </div>
                </main>
              </>
            } />
            <Route path="/opportunities" element={<OpportunitiesPage />} />
            <Route path="/opportunities/:id" element={<CompanyDetails />} />
            <Route path="/how-it-works" element={<HowItWorksPage />} />
            <Route path="/about" element={<AboutPage />} />
            <Route path="/investor-signup" element={<InvestorSignup />} />
            <Route path="/company-signup" element={<CompanySignup />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/get-started" element={<PersonaSelector />} />
            <Route path="/contact" element={<ContactPage />} />
            <Route path="/linkedin-callback" element={<LinkedInCallback />} />
          </Routes>
        </div>
        <Footer />
        <ChatWindow />
      </div>
    </Router>
  );
}

export default App;