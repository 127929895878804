export const ar = {
  nav: {
    opportunities: 'الفرص',
    howItWorks: 'نموذج العمل',
    about: 'عن المنصة',
    signIn: 'تسجيل الدخول',
    getStarted: 'ابدأ الآن'
  },
  hero: {
    title: 'سد الفجوات المالية',
    subtitle: 'تواصل مع الشركات الصغيرة والمتوسطة والناشئة المعتمدة التي تقدم عوائد تنافسية من خلال فرص تمويل الديون الآمنة',
    startInvesting: 'ابدأ الاستثمار',
    listBusiness: 'سجل شركتك'
  },
  stats: {
    opportunities: 'الفرص المدرجة',
    investors: 'المستثمرون النشطون',
    successRate: 'معدل النجاح'
  },
  companies: {
    title: 'فرص استثمارية مميزة',
    subtitle: 'اكتشف فرص استثمارية منتقاة مع شركات موثقة تقدم عوائد تنافسية من خلال تمويل الديون الآمن',
    investNow: 'استثمر الآن',
    signInToInvest: 'سجل الدخول للاستثمار',
    returnRate: 'معدل العائد',
    tenure: 'المدة',
    months: 'شهور',
    raised: 'تم جمع',
    goal: 'الهدف'
  },
  auth: {
    signIn: 'تسجيل الدخول إلى حسابك',
    orCreateAccount: 'أو',
    createAccount: 'إنشاء حساب جديد',
    emailAddress: 'البريد الإلكتروني',
    password: 'كلمة المرور',
    rememberMe: 'تذكرني',
    forgotPassword: 'نسيت كلمة المرور؟',
    signingIn: 'جاري تسجيل الدخول...',
    orContinueWith: 'أو المتابعة باستخدام',
    signInToInvestMessage: 'سجل الدخول لبدء الاستثمار في هذه الفرصة'
  },
  footer: {
    description: 'سد الفجوات المالية من خلال حلول تمويل الديون المبتكرة في منطقة الشرق الأوسط وشمال أفريقيا.',
    support: 'الدعم',
    connect: 'تواصل معنا',
    contactUs: 'اتصل بنا',
    termsConditions: 'الشروط والأحكام',
    privacyPolicy: 'سياسة الخصوصية',
    copyright: '© 2025 جينز. جميع الحقوق محفوظة.'
  }
};