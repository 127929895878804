import React, { useState } from 'react';
import { Send } from 'lucide-react';
import { useChatStore } from '../../lib/sarah/store';
import { getNextDialogue } from '../../lib/sarah/dialogue';

export const ChatInput: React.FC = () => {
  const [input, setInput] = useState('');
  const { addMessage } = useChatStore();

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (!input.trim()) return;

    addMessage({ type: 'user', content: input });
    
    setTimeout(() => {
      const dialogue = getNextDialogue('welcome');
      if (dialogue) {
        addMessage({ 
          type: 'bot', 
          content: "I understand you have a question. Let me help guide you through our platform's main features:",
          options: dialogue.options
        });
      }
    }, 500);

    setInput('');
  };

  return (
    <form onSubmit={handleSubmit} className="border-t p-4">
      <div className="flex space-x-2">
        <input
          type="text"
          value={input}
          onChange={(e) => setInput(e.target.value)}
          placeholder="Type your message..."
          className="flex-1 px-4 py-2 border border-gray-300 rounded-lg focus:ring-[#004BA8] focus:border-[#004BA8]"
        />
        <button
          type="submit"
          className="bg-[#004BA8] text-white p-2 rounded-lg hover:bg-[#003b86] transition-colors"
        >
          <Send size={20} />
        </button>
      </div>
    </form>
  );
};