import React from 'react';
import { CompanyCard } from '../components/CompanyCard';
import { generateMoreCompanies } from '../utils/generateCompanies';

export const OpportunitiesPage: React.FC = () => {
  const allCompanies = generateMoreCompanies(24);

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
      <div className="text-center mb-12">
        <h1 className="text-4xl font-bold text-gray-900 mb-4">Investment Opportunities</h1>
        <p className="text-xl text-gray-600 max-w-3xl mx-auto">
          Discover a wide range of vetted investment opportunities across various industries in the MENA region
        </p>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        {allCompanies.map((company) => (
          <CompanyCard key={company.id} company={company} />
        ))}
      </div>
    </div>
  );
};