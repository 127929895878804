import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { v4 as uuidv4 } from 'uuid';
import bcrypt from 'bcryptjs';
import { AuthState, SignUpData, User } from './types';

export const useAuthStore = create<AuthState>()(
  persist(
    (set) => ({
      user: null,
      isAuthenticated: false,
      isLoading: false,
      error: null,

      signUp: async (data: SignUpData) => {
        set({ isLoading: true, error: null });
        try {
          const hashedPassword = await bcrypt.hash(data.password, 10);
          const user: User = {
            id: uuidv4(),
            email: data.email,
            firstName: data.firstName,
            lastName: data.lastName,
            type: data.type,
            createdAt: new Date(),
          };

          // Store user data in localStorage
          localStorage.setItem(`user_${user.id}`, JSON.stringify({
            ...user,
            password: hashedPassword,
          }));

          set({ user, isAuthenticated: true, isLoading: false });
        } catch (error) {
          set({ error: 'Failed to create account', isLoading: false });
          throw error;
        }
      },

      signIn: async (email: string, password: string) => {
        set({ isLoading: true, error: null });
        try {
          // Search through localStorage for matching email
          const users = Object.keys(localStorage)
            .filter(key => key.startsWith('user_'))
            .map(key => JSON.parse(localStorage.getItem(key)!));

          const userMatch = users.find(u => u.email === email);
          
          if (!userMatch) {
            throw new Error('Invalid credentials');
          }

          const isValid = await bcrypt.compare(password, userMatch.password);
          if (!isValid) {
            throw new Error('Invalid credentials');
          }

          const { password: _, ...user } = userMatch;
          set({ user, isAuthenticated: true, isLoading: false });
        } catch (error) {
          set({ error: 'Invalid email or password', isLoading: false });
          throw error;
        }
      },

      signOut: () => {
        set({ user: null, isAuthenticated: false });
      },
    }),
    {
      name: 'auth-storage',
      partialize: (state) => ({ user: state.user, isAuthenticated: state.isAuthenticated }),
    }
  )
);