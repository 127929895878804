export const en = {
  nav: {
    opportunities: 'Opportunities',
    howItWorks: 'How it Works',
    about: 'About',
    signIn: 'Sign In',
    getStarted: 'Get Started'
  },
  hero: {
    title: 'Bridging Financial Gaps',
    subtitle: 'Connect with vetted SMEs and startups offering competitive returns through secure debt financing opportunities',
    startInvesting: 'Start Investing',
    listBusiness: 'List Your Business'
  },
  stats: {
    opportunities: 'Listed Opportunities',
    investors: 'Active Investors',
    successRate: 'Success Rate'
  },
  companies: {
    title: 'Featured Investment Opportunities',
    subtitle: 'Discover curated investment opportunities with verified companies offering competitive returns through secure debt financing',
    investNow: 'Invest Now',
    signInToInvest: 'Sign in to Invest',
    returnRate: 'Return Rate',
    tenure: 'Tenure',
    months: 'months',
    raised: 'Raised',
    goal: 'Goal'
  },
  auth: {
    signIn: 'Sign in to your account',
    orCreateAccount: 'Or',
    createAccount: 'create a new account',
    emailAddress: 'Email address',
    password: 'Password',
    rememberMe: 'Remember me',
    forgotPassword: 'Forgot your password?',
    signingIn: 'Signing in...',
    orContinueWith: 'Or continue with',
    signInToInvestMessage: 'Sign in to start investing in this opportunity'
  },
  footer: {
    description: 'Bridging financial gaps through innovative debt financing solutions across the MENA region.',
    support: 'Support',
    connect: 'Connect',
    contactUs: 'Contact Us',
    termsConditions: 'Terms & Conditions',
    privacyPolicy: 'Privacy Policy',
    copyright: '© 2025 Gainz. All rights reserved.'
  }
};