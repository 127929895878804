import React, { useEffect, useState } from 'react';
import { MessageSquare, X } from 'lucide-react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useChatStore } from '../../lib/sarah/store';
import { ChatMessages } from './ChatMessages';
import { ChatInput } from './ChatInput';
import { getPageGuide, getNextDialogue, getContextualPrompt } from '../../lib/sarah/dialogue';

export const ChatWindow: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { isOpen, toggleChat, addMessage, setCurrentPath, currentPath } = useChatStore();
  const [hasInteracted, setHasInteracted] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [popupMessage, setPopupMessage] = useState('');

  useEffect(() => {
    if (location.pathname !== currentPath) {
      setCurrentPath(location.pathname);
      
      if (!hasInteracted) {
        const guide = getPageGuide(location.pathname);
        if (guide) {
          setTimeout(() => {
            setPopupMessage(guide.message);
            setShowPopup(true);
            setTimeout(() => setShowPopup(false), 5000);
          }, guide.timing);
        }

        // Show contextual prompts periodically
        const interval = setInterval(() => {
          const contextualPrompt = getContextualPrompt(location.pathname);
          if (contextualPrompt && !isOpen) {
            setPopupMessage(contextualPrompt);
            setShowPopup(true);
            setTimeout(() => setShowPopup(false), 4000);
          }
        }, 15000);

        return () => clearInterval(interval);
      }
    }
  }, [location.pathname, currentPath, hasInteracted, isOpen]);

  const handleToggleChat = () => {
    setHasInteracted(true);
    setShowPopup(false);
    toggleChat();
    
    if (!isOpen) {
      const welcome = getNextDialogue('welcome');
      if (welcome) {
        setTimeout(() => {
          addMessage({ 
            type: 'bot', 
            content: welcome.message,
            options: welcome.options
          });
        }, 500);
      }
    }
  };

  const handleRedirect = (nextId: string) => {
    switch (nextId) {
      case 'redirect_investor_signup':
        navigate('/investor-signup');
        break;
      case 'redirect_company_signup':
        navigate('/company-signup');
        break;
      case 'redirect_opportunities':
        navigate('/opportunities');
        break;
    }
  };

  if (!isOpen) {
    return (
      <div className="fixed bottom-6 right-6 z-50">
        {showPopup && (
          <div className="mb-4 bg-white p-4 rounded-lg shadow-lg max-w-xs animate-fadeIn">
            <p className="text-sm text-gray-600">{popupMessage}</p>
            <button
              onClick={handleToggleChat}
              className="mt-2 text-sm text-[#004BA8] hover:text-[#003b86] font-medium"
            >
              Chat with me
            </button>
          </div>
        )}
        <button
          onClick={handleToggleChat}
          className="bg-[#004BA8] text-white p-4 rounded-full shadow-lg hover:bg-[#003b86] transition-all duration-300 transform hover:scale-110"
        >
          <MessageSquare size={24} />
          <span className="absolute -top-2 -right-2 bg-[#2F977E] text-white text-xs px-2 py-1 rounded-full animate-pulse">
            Online
          </span>
        </button>
      </div>
    );
  }

  return (
    <div className="fixed bottom-6 right-6 w-96 h-[600px] bg-white rounded-lg shadow-xl flex flex-col border border-gray-200 z-50">
      <div className="flex items-center justify-between p-4 bg-[#004BA8] text-white rounded-t-lg">
        <div className="flex items-center space-x-3">
          <div className="w-10 h-10 bg-white rounded-full flex items-center justify-center">
            <span className="text-[#004BA8] font-semibold text-lg">S</span>
          </div>
          <div>
            <h3 className="font-semibold">Sarah</h3>
            <p className="text-sm opacity-90">Gainz Support</p>
          </div>
        </div>
        <button
          onClick={handleToggleChat}
          className="text-white hover:text-gray-200 transition-colors"
        >
          <X size={20} />
        </button>
      </div>

      <ChatMessages onRedirect={handleRedirect} />
      <ChatInput />
    </div>
  );
};