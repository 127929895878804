import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { MapPin } from 'lucide-react';
import { Company } from '../types';
import { ProgressBar } from './ui/ProgressBar';
import { formatCurrency } from '../utils/format';
import { useAuthStore } from '../lib/auth/store';
import { Button } from './ui/Button';
import { useTranslation } from 'react-i18next';

interface CompanyCardProps {
  company: Company;
  showDetails?: boolean;
}

export const CompanyCard: React.FC<CompanyCardProps> = ({ company, showDetails = true }) => {
  const navigate = useNavigate();
  const { isAuthenticated } = useAuthStore();
  const { t, i18n } = useTranslation();

  const handleInvestClick = (e: React.MouseEvent) => {
    e.preventDefault();
    if (!isAuthenticated) {
      navigate('/login', { 
        state: { 
          from: `/opportunities/${company.id}`,
          message: t('auth.signInToInvestMessage')
        }
      });
    } else {
      navigate(`/opportunities/${company.id}`);
    }
  };

  return (
    <div className="bg-white rounded-xl shadow-lg hover:shadow-xl transition-shadow duration-300 overflow-hidden border border-gray-200">
      <Link to={`/opportunities/${company.id}`}>
        <div className="aspect-[16/9] relative overflow-hidden">
          <img
            src={company.logo}
            alt={company.name}
            className="w-full h-full object-cover transform hover:scale-105 transition-transform duration-300"
          />
        </div>
      </Link>
      <div className="p-6">
        <div className="flex items-center justify-between mb-4">
          <h3 className="text-xl font-bold text-gray-900">{company.name}</h3>
          <div className="flex items-center text-gray-600">
            <MapPin size={16} className="mr-1" />
            <span className="text-sm">{company.location}</span>
          </div>
        </div>
        <p className="text-gray-600 mb-4 line-clamp-2">
          {company.description[i18n.language as keyof typeof company.description]}
        </p>
        <div className="space-y-4">
          <ProgressBar
            current={company.currentFunding}
            total={company.fundingGoal}
            color="#2F977E"
          />
          <div className="flex justify-between text-sm">
            <span className="text-gray-600">
              {t('companies.raised')}: {formatCurrency(company.currentFunding)}
            </span>
            <span className="text-gray-600">
              {t('companies.goal')}: {formatCurrency(company.fundingGoal)}
            </span>
          </div>
          <div className="flex justify-between pt-4 border-t">
            <div>
              <p className="text-sm text-gray-600">{t('companies.returnRate')}</p>
              <p className="font-semibold text-[#2F977E]">{company.returnRate}%</p>
            </div>
            <div>
              <p className="text-sm text-gray-600">{t('companies.tenure')}</p>
              <p className="font-semibold text-[#004BA8]">
                {company.tenure} {t('companies.months')}
              </p>
            </div>
          </div>
          {showDetails && (
            <Button
              variant="primary"
              className="w-full mt-4"
              onClick={handleInvestClick}
            >
              {isAuthenticated ? t('companies.investNow') : t('companies.signInToInvest')}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};