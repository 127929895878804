import React from 'react';
import { useParams } from 'react-router-dom';
import { MapPin, Calendar, TrendingUp, Users, Building, Clock } from 'lucide-react';
import { companies } from '../data/companies';
import { Button } from '../components/ui/Button';
import { ProgressBar } from '../components/ui/ProgressBar';
import { formatCurrency } from '../utils/format';

export const CompanyDetails: React.FC = () => {
  const { id } = useParams();
  const company = companies.find(c => c.id === id);

  if (!company) {
    return <div>Company not found</div>;
  }

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      <div className="bg-white rounded-xl shadow-lg overflow-hidden">
        <div className="h-64 relative">
          <img
            src={company.logo}
            alt={company.name}
            className="w-full h-full object-cover"
          />
        </div>
        
        <div className="p-8">
          <div className="flex items-center justify-between mb-6">
            <h1 className="text-3xl font-bold text-gray-900">{company.name}</h1>
            <div className="flex items-center text-gray-600">
              <MapPin size={20} className="mr-2" />
              <span>{company.location}</span>
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-8">
            <div>
              <h2 className="text-xl font-semibold mb-4">Company Overview</h2>
              <p className="text-gray-600 mb-6">{company.description}</p>
              
              <div className="grid grid-cols-2 gap-4">
                <div className="flex items-center">
                  <Building className="text-[#004BA8] mr-2" />
                  <div>
                    <p className="text-sm text-gray-600">Industry</p>
                    <p className="font-semibold">{company.industry}</p>
                  </div>
                </div>
                <div className="flex items-center">
                  <Users className="text-[#004BA8] mr-2" />
                  <div>
                    <p className="text-sm text-gray-600">Team Size</p>
                    <p className="font-semibold">25-50</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="bg-gray-50 rounded-lg p-6">
              <h2 className="text-xl font-semibold mb-4">Investment Details</h2>
              <div className="space-y-6">
                <div>
                  <ProgressBar current={company.currentFunding} total={company.fundingGoal} />
                  <div className="flex justify-between mt-2 text-sm">
                    <span className="text-gray-600">Raised: {formatCurrency(company.currentFunding)}</span>
                    <span className="text-gray-600">Goal: {formatCurrency(company.fundingGoal)}</span>
                  </div>
                </div>

                <div className="grid grid-cols-2 gap-4">
                  <div className="flex items-center">
                    <TrendingUp className="text-[#2F977E] mr-2" />
                    <div>
                      <p className="text-sm text-gray-600">Return Rate</p>
                      <p className="font-semibold text-[#2F977E]">{company.returnRate}%</p>
                    </div>
                  </div>
                  <div className="flex items-center">
                    <Clock className="text-[#004BA8] mr-2" />
                    <div>
                      <p className="text-sm text-gray-600">Tenure</p>
                      <p className="font-semibold text-[#004BA8]">{company.tenure} months</p>
                    </div>
                  </div>
                </div>

                <Button variant="primary" className="w-full">
                  Invest Now
                </Button>
              </div>
            </div>
          </div>

          <div className="border-t pt-8">
            <h2 className="text-xl font-semibold mb-4">Key Financials</h2>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
              <div className="bg-gray-50 p-4 rounded-lg">
                <p className="text-sm text-gray-600">Annual Revenue</p>
                <p className="text-xl font-semibold text-[#004BA8]">{formatCurrency(2500000)}</p>
              </div>
              <div className="bg-gray-50 p-4 rounded-lg">
                <p className="text-sm text-gray-600">Profit Margin</p>
                <p className="text-xl font-semibold text-[#2F977E]">25%</p>
              </div>
              <div className="bg-gray-50 p-4 rounded-lg">
                <p className="text-sm text-gray-600">Growth Rate</p>
                <p className="text-xl font-semibold text-[#FFBE00]">45%</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};