import { create } from 'zustand';
import { ChatState, Message } from './types';

export const useChatStore = create<ChatState>((set) => ({
  messages: [],
  isOpen: false,
  currentPath: '/',
  addMessage: (message) => 
    set((state) => ({
      messages: [...state.messages, {
        ...message,
        id: Math.random().toString(36).substring(7),
        timestamp: new Date()
      }]
    })),
  toggleChat: () => set((state) => ({ isOpen: !state.isOpen })),
  setCurrentPath: (path) => set({ currentPath: path })
}));