import React, { useState } from 'react';
import { Button } from '../components/ui/Button';

const nationalities = [
  'UAE', 'Saudi Arabia', 'Kuwait', 'Qatar', 'Bahrain', 
  'Oman', 'Egypt', 'Jordan', 'Lebanon', 'Other'
];

const idTypes = [
  'National ID', 'Passport', 'Residence Permit', 'Driver\'s License'
];

const industries = [
  'Technology', 'Healthcare', 'Fintech', 'E-commerce', 'Clean Energy',
  'Education', 'Logistics', 'Food Tech', 'Real Estate', 'Manufacturing'
];

const geographies = [
  'GCC', 'Levant', 'North Africa', 'All MENA', 'Global'
];

const ticketSizes = [
  '$1,000 - $10,000', 
  '$10,000 - $50,000', 
  '$50,000 - $100,000', 
  '$100,000+'
];

export const InvestorSignup: React.FC = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    middleName: '',
    lastName: '',
    age: '',
    nationality: '',
    idType: '',
    ticketSize: '',
    industry: '',
    geography: ''
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  return (
    <div className="max-w-3xl mx-auto px-4 py-16">
      <h1 className="text-3xl font-bold text-gray-900 mb-8">Investor Registration</h1>
      
      <form className="space-y-6">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              First Name
            </label>
            <input
              type="text"
              name="firstName"
              value={formData.firstName}
              onChange={handleChange}
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-[#004BA8] focus:border-[#004BA8]"
              required
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Middle Name
            </label>
            <input
              type="text"
              name="middleName"
              value={formData.middleName}
              onChange={handleChange}
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-[#004BA8] focus:border-[#004BA8]"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Last Name
            </label>
            <input
              type="text"
              name="lastName"
              value={formData.lastName}
              onChange={handleChange}
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-[#004BA8] focus:border-[#004BA8]"
              required
            />
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Age
            </label>
            <input
              type="number"
              name="age"
              value={formData.age}
              onChange={handleChange}
              min="18"
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-[#004BA8] focus:border-[#004BA8]"
              required
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Nationality
            </label>
            <select
              name="nationality"
              value={formData.nationality}
              onChange={handleChange}
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-[#004BA8] focus:border-[#004BA8]"
              required
            >
              <option value="">Select Nationality</option>
              {nationalities.map(nationality => (
                <option key={nationality} value={nationality}>{nationality}</option>
              ))}
            </select>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              ID Type
            </label>
            <select
              name="idType"
              value={formData.idType}
              onChange={handleChange}
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-[#004BA8] focus:border-[#004BA8]"
              required
            >
              <option value="">Select ID Type</option>
              {idTypes.map(type => (
                <option key={type} value={type}>{type}</option>
              ))}
            </select>
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Investment Ticket Size
            </label>
            <select
              name="ticketSize"
              value={formData.ticketSize}
              onChange={handleChange}
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-[#004BA8] focus:border-[#004BA8]"
              required
            >
              <option value="">Select Ticket Size</option>
              {ticketSizes.map(size => (
                <option key={size} value={size}>{size}</option>
              ))}
            </select>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Preferred Industry
            </label>
            <select
              name="industry"
              value={formData.industry}
              onChange={handleChange}
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-[#004BA8] focus:border-[#004BA8]"
              required
            >
              <option value="">Select Industry</option>
              {industries.map(industry => (
                <option key={industry} value={industry}>{industry}</option>
              ))}
            </select>
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Preferred Geography
            </label>
            <select
              name="geography"
              value={formData.geography}
              onChange={handleChange}
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-[#004BA8] focus:border-[#004BA8]"
              required
            >
              <option value="">Select Geography</option>
              {geographies.map(geography => (
                <option key={geography} value={geography}>{geography}</option>
              ))}
            </select>
          </div>
        </div>

        <Button variant="primary" className="w-full" type="submit">
          Continue to Step 2
        </Button>
      </form>
    </div>
  );
};