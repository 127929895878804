import React, { useState } from 'react';
import { Button } from '../components/ui/Button';

const industries = [
  'Technology', 'Healthcare', 'Fintech', 'E-commerce', 'Clean Energy',
  'Education', 'Logistics', 'Food Tech', 'Real Estate', 'Manufacturing'
];

const countries = [
  'United Arab Emirates', 'Saudi Arabia', 'Kuwait', 'Qatar', 'Bahrain',
  'Oman', 'Egypt', 'Jordan', 'Lebanon', 'Other'
];

const states = {
  'United Arab Emirates': ['Dubai', 'Abu Dhabi', 'Sharjah', 'Ajman', 'RAK', 'Fujairah', 'UAQ'],
  'Saudi Arabia': ['Riyadh', 'Jeddah', 'Dammam', 'Mecca', 'Medina'],
  // Add more states for other countries
};

const geographies = [
  'GCC', 'Levant', 'North Africa', 'All MENA', 'Global'
];

export const CompanySignup: React.FC = () => {
  const [formData, setFormData] = useState({
    companyName: '',
    legalName: '',
    crNumber: '',
    country: '',
    state: '',
    industry: '',
    geography: ''
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value,
      ...(name === 'country' ? { state: '' } : {})
    }));
  };

  return (
    <div className="max-w-3xl mx-auto px-4 py-16">
      <h1 className="text-3xl font-bold text-gray-900 mb-8">Company Registration</h1>
      
      <form className="space-y-6">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Company Name
            </label>
            <input
              type="text"
              name="companyName"
              value={formData.companyName}
              onChange={handleChange}
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-[#004BA8] focus:border-[#004BA8]"
              required
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Legal Name
            </label>
            <input
              type="text"
              name="legalName"
              value={formData.legalName}
              onChange={handleChange}
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-[#004BA8] focus:border-[#004BA8]"
              required
            />
          </div>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Commercial Register Number
          </label>
          <input
            type="text"
            name="crNumber"
            value={formData.crNumber}
            onChange={handleChange}
            className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-[#004BA8] focus:border-[#004BA8]"
            required
          />
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Country of Incorporation
            </label>
            <select
              name="country"
              value={formData.country}
              onChange={handleChange}
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-[#004BA8] focus:border-[#004BA8]"
              required
            >
              <option value="">Select Country</option>
              {countries.map(country => (
                <option key={country} value={country}>{country}</option>
              ))}
            </select>
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              State/Emirate
            </label>
            <select
              name="state"
              value={formData.state}
              onChange={handleChange}
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-[#004BA8] focus:border-[#004BA8]"
              required
              disabled={!formData.country || !states[formData.country as keyof typeof states]}
            >
              <option value="">Select State</option>
              {formData.country && states[formData.country as keyof typeof states]?.map(state => (
                <option key={state} value={state}>{state}</option>
              ))}
            </select>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Industry
            </label>
            <select
              name="industry"
              value={formData.industry}
              onChange={handleChange}
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-[#004BA8] focus:border-[#004BA8]"
              required
            >
              <option value="">Select Industry</option>
              {industries.map(industry => (
                <option key={industry} value={industry}>{industry}</option>
              ))}
            </select>
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Geography of Operations
            </label>
            <select
              name="geography"
              value={formData.geography}
              onChange={handleChange}
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-[#004BA8] focus:border-[#004BA8]"
              required
            >
              <option value="">Select Geography</option>
              {geographies.map(geography => (
                <option key={geography} value={geography}>{geography}</option>
              ))}
            </select>
          </div>
        </div>

        <Button variant="primary" className="w-full" type="submit">
          Continue to Step 2
        </Button>
      </form>
    </div>
  );
};