import React from 'react';
import { Users, Building, TrendingUp, Shield, FileCheck, Banknote } from 'lucide-react';

const steps = [
  {
    title: 'For Investors',
    items: [
      {
        icon: Users,
        title: 'Create Account',
        description: 'Sign up and complete KYC verification to start investing'
      },
      {
        icon: FileCheck,
        title: 'Browse Opportunities',
        description: 'Explore vetted companies and their investment terms'
      },
      {
        icon: Banknote,
        title: 'Invest & Earn',
        description: 'Deploy capital from $1,000 and earn 15-20% returns'
      }
    ]
  },
  {
    title: 'For Companies',
    items: [
      {
        icon: Building,
        title: 'List Your Business',
        description: 'Submit company details and financial documentation'
      },
      {
        icon: Shield,
        title: 'Get Verified',
        description: 'Pass our rigorous verification process'
      },
      {
        icon: TrendingUp,
        title: 'Raise Capital',
        description: 'Access working capital through debt financing'
      }
    ]
  }
];

export const HowItWorksPage: React.FC = () => {
  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
      <div className="text-center mb-16">
        <h1 className="text-4xl font-bold text-gray-900 mb-4">How Gainz Works</h1>
        <p className="text-xl text-gray-600 max-w-3xl mx-auto">
          Gainz connects businesses seeking growth capital with investors looking for attractive returns through a seamless, secure platform.
        </p>
      </div>

      <div className="space-y-16">
        {steps.map((section, index) => (
          <div key={index} className="bg-white rounded-xl shadow-lg p-8">
            <h2 className="text-2xl font-bold text-[#004BA8] mb-8">{section.title}</h2>
            <div className="grid md:grid-cols-3 gap-8">
              {section.items.map((item, itemIndex) => (
                <div key={itemIndex} className="flex flex-col items-center text-center">
                  <div className="w-16 h-16 bg-[#004BA8]/10 rounded-full flex items-center justify-center mb-4">
                    <item.icon size={32} className="text-[#004BA8]" />
                  </div>
                  <h3 className="text-xl font-semibold mb-2">{item.title}</h3>
                  <p className="text-gray-600">{item.description}</p>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};