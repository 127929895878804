import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuthStore } from '../lib/auth/store';

export const LinkedInCallback: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { signIn } = useAuthStore();

  useEffect(() => {
    const handleCallback = async () => {
      const params = new URLSearchParams(location.search);
      const code = params.get('code');
      const state = params.get('state');
      const storedState = localStorage.getItem('linkedin_oauth_state');

      if (!code || !state || state !== storedState) {
        navigate('/login', { state: { error: 'LinkedIn authentication failed' } });
        return;
      }

      try {
        // Here you would typically send the code to your backend
        // For now, we'll just simulate a successful login
        await signIn('linkedin@example.com', 'dummy-password');
        navigate('/');
      } catch (error) {
        navigate('/login', { state: { error: 'Failed to authenticate with LinkedIn' } });
      } finally {
        localStorage.removeItem('linkedin_oauth_state');
      }
    };

    handleCallback();
  }, [location, navigate, signIn]);

  return (
    <div className="min-h-screen flex items-center justify-center">
      <div className="text-center">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-[#004BA8] mx-auto"></div>
        <p className="mt-4 text-gray-600">Completing LinkedIn authentication...</p>
      </div>
    </div>
  );
};