import React from 'react';
import { Button } from './ui/Button';

interface LinkedInButtonProps {
  onSuccess: (code: string) => void;
  onError: (error: Error) => void;
}

export const LinkedInButton: React.FC<LinkedInButtonProps> = ({ onSuccess, onError }) => {
  const handleClick = () => {
    const state = Math.random().toString(36).substring(7);
    localStorage.setItem('linkedin_oauth_state', state);

    const clientId = import.meta.env.VITE_LINKEDIN_CLIENT_ID;
    const redirectUri = import.meta.env.VITE_LINKEDIN_REDIRECT_URI;
    const scope = 'r_emailaddress r_liteprofile';

    const params = new URLSearchParams({
      response_type: 'code',
      client_id: clientId,
      redirect_uri: redirectUri,
      state,
      scope,
    });

    window.location.href = `https://www.linkedin.com/oauth/v2/authorization?${params.toString()}`;
  };

  return (
    <Button
      onClick={handleClick}
      className="w-full flex items-center justify-center gap-2 bg-[#0077B5] hover:bg-[#006097]"
    >
      <svg viewBox="0 0 24 24" className="w-5 h-5 fill-current">
        <path d="M19 3a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h14m-.5 15.5v-5.3a3.26 3.26 0 0 0-3.26-3.26c-.85 0-1.84.52-2.32 1.3v-1.11h-2.79v8.37h2.79v-4.93c0-.77.62-1.4 1.39-1.4a1.4 1.4 0 0 1 1.4 1.4v4.93h2.79M6.88 8.56a1.68 1.68 0 0 0 1.68-1.68c0-.93-.75-1.69-1.68-1.69a1.69 1.69 0 0 0-1.69 1.69c0 .93.76 1.68 1.69 1.68m1.39 9.94v-8.37H5.5v8.37h2.77z"/>
      </svg>
      Sign in with LinkedIn
    </Button>
  );
};