import React from 'react';
import { Link } from 'react-router-dom';
import { CircleDollarSign } from 'lucide-react';
import { Button } from './ui/Button';
import { LanguageSwitcher } from './LanguageSwitcher';
import { useTranslation } from 'react-i18next';

export const Navbar: React.FC = () => {
  const { t } = useTranslation();

  return (
    <nav className="bg-white shadow-sm">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between h-16 items-center">
          <Link to="/" className="flex items-center space-x-2">
            <CircleDollarSign size={32} className="text-[#004BA8]" />
            <span className="text-2xl font-bold text-[#004BA8]">Gainz</span>
          </Link>
          
          <div className="hidden md:flex items-center space-x-8">
            <Link to="/opportunities" className="text-lg font-medium text-[#004BA8] hover:text-[#003b86] transition-colors">
              {t('nav.opportunities')}
            </Link>
            <Link to="/how-it-works" className="text-lg font-medium text-[#004BA8] hover:text-[#003b86] transition-colors">
              {t('nav.howItWorks')}
            </Link>
            <Link to="/about" className="text-lg font-medium text-[#004BA8] hover:text-[#003b86] transition-colors">
              {t('nav.about')}
            </Link>
            <LanguageSwitcher />
          </div>
          
          <div className="flex items-center space-x-4">
            <Link to="/login">
              <Button variant="primary" size="sm">
                {t('nav.signIn')}
              </Button>
            </Link>
            <Link to="/get-started">
              <Button variant="primary" size="sm">
                {t('nav.getStarted')}
              </Button>
            </Link>
          </div>
        </div>
      </div>
    </nav>
  );
};