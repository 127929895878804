import React from 'react';
import { TrendingUp, Shield, Users } from 'lucide-react';
import { Button } from './ui/Button';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const stats = [
  { icon: TrendingUp, value: '50+', label: 'stats.opportunities' },
  { icon: Users, value: '25+', label: 'stats.investors' },
  { icon: Shield, value: '100%', label: 'stats.successRate' },
];

export const Hero: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className="relative bg-white text-[#004BA8]">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-24">
        <div className="text-center max-w-3xl mx-auto">
          <h1 className="text-5xl font-bold mb-6">
            {t('hero.title')}
          </h1>
          <p className="text-xl mb-8 text-gray-600">
            {t('hero.subtitle')}
          </p>
          <div className="flex gap-6 justify-center">
            <Link to="/investor-signup">
              <Button variant="primary" size="lg">
                {t('hero.startInvesting')}
              </Button>
            </Link>
            <Link to="/company-signup">
              <Button variant="primary" size="lg">
                {t('hero.listBusiness')}
              </Button>
            </Link>
          </div>
        </div>
        
        <div className="mt-16 grid grid-cols-1 gap-8 sm:grid-cols-3">
          {stats.map((stat, index) => (
            <div
              key={index}
              className="flex flex-col items-center justify-center p-8 bg-[#004BA8] rounded-lg shadow-lg h-48 w-full"
            >
              <stat.icon size={32} className="mb-4 text-white" />
              <div className="text-3xl font-bold mb-2 text-white">{stat.value}</div>
              <div className="text-lg text-white">{t(stat.label)}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};