import React from 'react';

export const AboutPage: React.FC = () => {
  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
      <div className="max-w-4xl mx-auto">
        <h1 className="text-4xl font-bold text-gray-900 mb-8">About Gainz</h1>
        
        <div className="prose prose-lg">
          <p className="text-gray-600 mb-6">
            Gainz is a cutting-edge crowdfunding platform designed to connect retail investors, SMEs, startups, and institutional investors across the MENA region. With a strong focus on debt financing for working capital, Gainz empowers businesses to access vital funds while offering investors attractive returns ranging from 15% to 20% on tenures of 6 to 12 months.
          </p>
          
          <p className="text-gray-600 mb-6">
            Our platform simplifies the fundraising and investment process, providing intuitive tools for founders to list their companies and investors to deploy capital seamlessly, starting from a minimum of $1,000. Gainz bridges financial gaps by integrating advanced features like secure KYC verification, document uploads, personalized dashboards, and transparent progress tracking for funding goals.
          </p>
          
          <p className="text-gray-600">
            Built for scalability and efficiency, Gainz leverages cutting-edge technology to ensure a seamless experience for 1M+ users across diverse industries. With a modern, user-centric design utilizing bold branding colors (#004BA8, #FFBE00, #2F977E), we aim to deliver an innovative platform that fosters financial growth and collaboration. Gainz is your trusted partner in building wealth, one opportunity at a time.
          </p>
        </div>
      </div>
    </div>
  );
};