import { DialogueNode, GuidePrompt } from './types';

export const dialogue: DialogueNode[] = [
  {
    id: 'welcome',
    message: "👋 Hi! I'm Sarah from Gainz. Let me help you discover:\n\n💰 How to earn 15-20% secure returns\n🚀 Quick access to growth capital\n\nWhat brings you here today?",
    options: [
      { label: 'Looking to Invest', value: 'invest', nextId: 'invest_intro' },
      { label: 'Need Funding', value: 'funding', nextId: 'funding_intro' },
      { label: 'Just Exploring', value: 'explore', nextId: 'platform_overview' }
    ],
    autoPrompt: true,
    delay: 1000
  },
  {
    id: 'invest_intro',
    message: "Perfect! Let me show you how Gainz can help grow your wealth:\n\n✨ Start with just $1,000\n📈 Earn 15-20% annual returns\n🔒 Fully secured investments\n💫 Monthly payouts\n\nWould you like to:",
    options: [
      { label: 'See Live Opportunities', value: 'opportunities', nextId: 'redirect_opportunities' },
      { label: 'Start Investing Now', value: 'signup', nextId: 'investor_signup_start' },
      { label: 'Learn More', value: 'learn', nextId: 'investment_process' }
    ]
  },
  {
    id: 'investor_signup_start',
    message: "Great choice! I'll help you get started right away. Creating your investor account takes just 5 minutes. Ready to begin?",
    options: [
      { label: 'Start Now', value: 'start', nextId: 'redirect_investor_signup' },
      { label: 'Have Questions', value: 'questions', nextId: 'investor_faq' }
    ]
  },
  {
    id: 'funding_intro',
    message: "I'll help you access the capital your business needs!\n\n💫 Quick funding process\n🤝 No equity dilution\n📊 Flexible terms\n💰 Competitive rates\n\nShall we check your eligibility?",
    options: [
      { label: 'Check Eligibility', value: 'check', nextId: 'funding_eligibility' },
      { label: 'Apply Now', value: 'apply', nextId: 'redirect_company_signup' },
      { label: 'Tell Me More', value: 'more', nextId: 'funding_process' }
    ]
  }
];

export const pageGuides: GuidePrompt[] = [
  {
    path: '/',
    message: "💡 Did you know? Gainz investors earn 15-20% annual returns. Want to learn how?",
    timing: 3000
  },
  {
    path: '/opportunities',
    message: "🎯 Looking for the perfect investment? I can help you find opportunities matching your criteria!",
    timing: 2000
  },
  {
    path: '/investor-signup',
    message: "Need help with your application? I'm here to guide you through each step!",
    timing: 1500
  },
  {
    path: '/company-signup',
    message: "👋 Ready to get funded? Let me help you complete your application in just a few minutes!",
    timing: 1500
  }
];

export const contextualPrompts: Record<string, string[]> = {
  '/opportunities': [
    "💎 These opportunities are carefully vetted by our team",
    "🔍 Need help finding the right investment?",
    "💡 You can start investing with just $1,000"
  ],
  '/how-it-works': [
    "👋 Want a quick overview of how Gainz works?",
    "🎯 I can show you how to get started in minutes",
    "💫 Looking to invest or raise funds?"
  ]
};

export const getNextDialogue = (id: string): DialogueNode | undefined => {
  return dialogue.find(node => node.id === id);
};

export const getPageGuide = (path: string): GuidePrompt | undefined => {
  return pageGuides.find(guide => guide.path === path);
};

export const getContextualPrompt = (path: string): string | undefined => {
  const prompts = contextualPrompts[path];
  return prompts ? prompts[Math.floor(Math.random() * prompts.length)] : undefined;
};